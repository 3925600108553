import React, { useState, useEffect, useRef } from 'react';
import { getGlobalBestScore, getLeaderboard, submitGameScore } from '../../api/user';
import { useNavigate } from 'react-router-dom';

const FoodGame = () => {
    const [foods, setFoods] = useState([]);
    const [score, setScore] = useState(0);
    const [isDrawing, setIsDrawing] = useState(false);
    const [bladeTrail, setBladeTrail] = useState([]);
    const [difficulty, setDifficulty] = useState(1);
    const [splashes, setSplashes] = useState([]);
    const [combos, setCombos] = useState([]);
    const [gameOver, setGameOver] = useState(false);
    const [lastSliceTime, setLastSliceTime] = useState(0);
    const [lastTouch, setLastTouch] = useState(null);
    const [isTouching, setIsTouching] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [showLeaderboard, setShowLeaderboard] = useState(false);
    const [isNewHighScore, setIsNewHighScore] = useState(false);

    const gameAreaRef = useRef(null);
    const animationFrameRef = useRef();
    const lastSpawnTime = useRef(0);
    const gravity = 0.08;
    const [submitError, setSubmitError] = useState(null);
    const [globalBest, setGlobalBest] = useState({ score: 0, playerName: 'None' });
    const [lives, setLives] = useState(2);

    const navigate = useNavigate();


    const foodTypes = [
        {
            type: '🍕',
            points: 10,
            cuisine: 'italian',
            splashColor: '#ff6b6b'
        },
        {
            type: '🍝',
            points: 15,
            cuisine: 'italian',
            splashColor: '#ff6b6b'
        },
        {
            type: '🥘',
            points: 20,
            cuisine: 'indian',
            splashColor: '#ffd93d'
        },
        {
            type: '🍛',
            points: 25,
            cuisine: 'indian',
            splashColor: '#ffd93d'
        },
        {
            type: '💣',
            points: 0,
            cuisine: 'bomb',
            splashColor: '#ff0000'
        }
    ];

    useEffect(() => {
        const fetchGlobalBest = async () => {
            try {
                const response = await getGlobalBestScore();
                if (response.data) {
                    setGlobalBest(response.data);
                }
            } catch (error) {
                console.error('Error fetching global best score:', error);
            }
        };

        fetchGlobalBest();
    }, [gameOver]);

    const handleGameOver = async () => {
        try {
            const phone = localStorage.getItem("OG_phone");
            if (!phone) {
                setSubmitError("Please log in to save your score!");
                setShowLeaderboard(true);
                return;
            }

            // Submit score to backend
            const result = await submitGameScore(score);
            const currentBest = localStorage.getItem('bestScore') || 0;

            // Check if it's a new high score
            if (score > currentBest) {
                localStorage.setItem('bestScore', score.toString());
                setIsNewHighScore(true);
            }

            // Fetch updated leaderboard
            const leaderboardData = await getLeaderboard(10);
            if (leaderboardData.data) {
                setLeaderboard(leaderboardData.data);
            }

            setShowLeaderboard(true);
            setSubmitError(null); // Clear any previous errors
        } catch (error) {
            console.error('Error handling game over:', error);
            setSubmitError("Failed to submit score. Please try again.");
            setShowLeaderboard(true);
        }
    };


    // Device detection
    useEffect(() => {
        setIsMobile('ontouchstart' in window);
    }, []);

    // Handle viewport height
    useEffect(() => {
        const updateHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            // Update game area height
            if (gameAreaRef.current) {
                const height = isMobile ? `${window.innerHeight * 0.8}px` : '400px';
                gameAreaRef.current.style.height = height;
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, [isMobile]);

    // Game loop
    useEffect(() => {
        if (gameOver) {
            cancelAnimationFrame(animationFrameRef.current);
            return;
        }
    
        let lastTime = 0;
        const gameLoop = (timestamp) => {
            const deltaTime = timestamp - lastTime;
            lastTime = timestamp;
    
            // Spawn new food groups
            const spawnInterval = Math.max(2000 - (difficulty * 100), 800);
            if (timestamp - lastSpawnTime.current > spawnInterval) {
                spawnFoodGroup();
                lastSpawnTime.current = timestamp;
            }
    
            // Update foods positions
            setFoods(prevFoods => {
                const newFoods = prevFoods
                    .map(food => {
                        if (food.sliced) {
                            return {
                                ...food,
                                leftHalfX: food.leftHalfX + food.leftVelocityX,
                                leftHalfY: food.leftHalfY + food.leftVelocityY,
                                rightHalfX: food.rightHalfX + food.rightVelocityX,
                                rightHalfY: food.rightHalfY + food.rightVelocityY,
                                leftVelocityY: food.leftVelocityY + gravity,
                                rightVelocityY: food.rightVelocityY + gravity,
                            };
                        }
    
                        const newVelocityY = food.velocity.y + (gravity * deltaTime/16);
                        return {
                            ...food,
                            y: food.y + (food.velocity.y * deltaTime/16),
                            x: food.x + (food.velocity.x * deltaTime/16),
                            velocity: {
                                ...food.velocity,
                                y: newVelocityY
                            },
                            rotation: (food.rotation || 0) + food.rotationSpeed,
                        };
                    });
    
                // Check for missed fruits
                const missedFruits = newFoods.filter(food => {
                    const timeAlive = Date.now() - (food.spawnTime || 0);
                    const isMissed = food.y >= (gameAreaRef.current?.clientHeight || 400) + 100;
                    return isMissed && !food.sliced && food.cuisine !== 'bomb' && timeAlive < 10000;
                });
    
                if (missedFruits.length > 0) {
                    setLives(prev => {
                        const newLives = prev - missedFruits.length;
                        if (newLives <= 0) {
                            setGameOver(true);
                            handleGameOver();
                        }
                        return Math.max(0, newLives);
                    });
                }
    
                return newFoods.filter(food => {
                    const timeAlive = Date.now() - (food.spawnTime || 0);
                    return food.y < (gameAreaRef.current?.clientHeight || 400) + 100 && timeAlive < 10000;
                });
            });
    
            // Clean up effects
            setSplashes(prev => prev.filter(splash => Date.now() - splash.timestamp < 500));
            setCombos(prev => prev.filter(combo => Date.now() - combo.timestamp < 800));
    
            animationFrameRef.current = requestAnimationFrame(gameLoop);
        };
    
        animationFrameRef.current = requestAnimationFrame(gameLoop);
        return () => cancelAnimationFrame(animationFrameRef.current);
    }, [difficulty, gameOver]);

    // Score effect
    useEffect(() => {
        setDifficulty(1 + Math.floor(score / 100));
    }, [score]);

    const spawnFoodGroup = () => {
        const gameWidth = gameAreaRef.current?.clientWidth || 400;
        const groupSize = Math.min(Math.floor(1 + Math.random() * (2 + difficulty)), 5);
        // Increased bomb probability
        const includeBomb = Math.random() < 0.20 + (difficulty * 0.03); // Increased from 0.15 to 0.20
    
        const spawnPositions = Array.from({ length: groupSize }, () => ({
            x: Math.random() * gameWidth,
            delay: Math.random() * 300
        }));
    
        spawnPositions.forEach((pos, index) => {
            setTimeout(() => {
                const isBomb = includeBomb && index === Math.floor(Math.random() * groupSize);
                spawnSingleFood(pos.x, isBomb);
            }, pos.delay);
        });
    };

    const spawnSingleFood = (spawnX, isBomb) => {
        const gameWidth = gameAreaRef.current?.clientWidth || 400;
        const gameHeight = gameAreaRef.current?.clientHeight || 400;

        const food = isBomb ? foodTypes[4] : foodTypes[Math.floor(Math.random() * (foodTypes.length - 1))];

        const startY = gameHeight + 50;
        const targetX = gameWidth * (0.2 + Math.random() * 0.6);
        const maxHeight = gameHeight * (0.5 + Math.random() * 0.2);

        const timeToTarget = 0.8 + Math.random() * 0.4;
        const velocityX = (targetX - spawnX) / (timeToTarget * 60);
        const velocityY = -Math.sqrt(2 * gravity * maxHeight);

        const newFood = {
            id: Math.random(),
            x: spawnX,
            y: startY,
            type: food.type,
            cuisine: food.cuisine,
            splashColor: food.splashColor,
            points: food.points,
            sliced: false,
            velocity: {
                x: velocityX,
                y: velocityY
            },
            rotation: Math.random() * 360,
            rotationSpeed: (Math.random() - 0.5) * 4,
            spawnTime: Date.now()
        };

        setFoods(prev => [...prev, newFood]);
    };

    const getTouchPosition = (touch) => {
        const rect = gameAreaRef.current.getBoundingClientRect();
        return {
            x: touch.clientX - rect.left,
            y: touch.clientY - rect.top
        };
    };

    const handleTouchStart = (e) => {
        e.preventDefault();
        if (gameOver) return;

        const touch = e.touches[0];
        const pos = getTouchPosition(touch);
        setIsTouching(true);
        setLastTouch(pos);
        setBladeTrail([{ ...pos, time: Date.now() }]);
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        if (!isTouching || gameOver) return;

        const touch = e.touches[0];
        const pos = getTouchPosition(touch);
        const currentTime = Date.now();

        setBladeTrail(prev => {
            const newTrail = [...prev, { ...pos, time: currentTime }];
            return newTrail.filter(point => currentTime - point.time < 50);
        });

        checkCollisions(pos, currentTime);
        setLastTouch(pos);
    };

    const handleTouchEnd = (e) => {
        e.preventDefault();
        setIsTouching(false);
        setBladeTrail([]);
        setLastTouch(null);
    };

    const handleMouseDown = (e) => {
        if (gameOver) return;
        const rect = gameAreaRef.current.getBoundingClientRect();
        const pos = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };
        setIsDrawing(true);
        setBladeTrail([{ ...pos, time: Date.now() }]);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing || gameOver) return;

        const rect = gameAreaRef.current.getBoundingClientRect();
        const pos = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };
        const currentTime = Date.now();

        setBladeTrail(prev => {
            const newTrail = [...prev, { ...pos, time: currentTime }];
            return newTrail.filter(point => currentTime - point.time < 50);
        });

        checkCollisions(pos, currentTime);
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
        setBladeTrail([]);
    };

    const checkCollisions = (currentPos, currentTime) => {
        setFoods(prevFoods =>
            prevFoods.map(food => {
                if (!food.sliced && bladeTrail.some((point, index) => {
                    if (index === 0) return false;
                    return isPointNearLine(
                        food.x,
                        food.y,
                        point.x,
                        point.y,
                        bladeTrail[index - 1].x,
                        bladeTrail[index - 1].y,
                        isMobile ? 40 : 30
                    );
                })) {
                    if (food.cuisine === 'bomb') {
                        setGameOver(true);
                        handleGameOver(); // Add this line to handle game over
                        createSplash(food.x, food.y, food.splashColor);
                        return food;
                    }

                    createSplash(food.x, food.y, food.splashColor);
                    setScore(prev => prev + food.points);

                    return {
                        ...food,
                        sliced: true,
                        sliceTime: currentTime,
                        leftHalfX: food.x - 15,
                        leftHalfY: food.y,
                        rightHalfX: food.x + 15,
                        rightHalfY: food.y,
                        leftVelocityX: -2 - Math.random() * 2,
                        leftVelocityY: food.velocity.y * 0.5,
                        rightVelocityX: 2 + Math.random() * 2,
                        rightVelocityY: food.velocity.y * 0.5,
                    };
                }
                return food;
            })
        );
    };

    const createSplash = (x, y, color) => {
        setSplashes(prev => {
            const newSplashes = prev.filter(splash => Date.now() - splash.timestamp < 500);
            return [...newSplashes, {
                id: Math.random(),
                x,
                y,
                color,
                timestamp: Date.now(),
                particles: Array.from({ length: 8 }, (_, i) => ({
                    angle: (i * Math.PI * 2) / 8,
                    speed: 2 + Math.random() * 2
                }))
            }];
        });
    };

    const isPointNearLine = (px, py, x1, y1, x2, y2, tolerance) => {
        const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
        if (length === 0) return false;

        const t = Math.max(0, Math.min(1, ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) / (length * length)));
        const nearestX = x1 + t * (x2 - x1);
        const nearestY = y1 + t * (y2 - y1);

        return Math.sqrt((px - nearestX) ** 2 + (py - nearestY) ** 2) < tolerance;
    };

    const handleRestart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setGameOver(false);
        setScore(0);
        setLives(2);
        setFoods([]);
        setSplashes([]);
        setShowLeaderboard(false);
        setIsNewHighScore(false);
    };


    return (
        <div className="w-full h-full max-w-4xl mx-auto">
        <div className={`text-3xl font-bold mb-2 text-center text-yellow-400 drop-shadow-lg
            ${isMobile ? 'text-2xl mb-1' : 'text-3xl mb-4'}`}>
            <div className="text-5xl mb-1">{score}</div>
            <div className={`text-yellow-300 ${isMobile ? 'text-sm' : 'text-lg'}`}>
                Best: {globalBest.score}
            </div>
            <div className={`text-red-500 ${isMobile ? 'text-sm' : 'text-lg'}`}>
                Lives: {'❤️'.repeat(lives)}
            </div>
        </div>

            <div
                ref={gameAreaRef}
                className="relative w-full overflow-hidden rounded-lg select-none touch-none"
                style={{
                    height: isMobile ? 'calc(var(--vh, 1vh) * 80)' : '400px',
                    background: 'linear-gradient(to bottom, #1a1a2e, #16213e)',
                    touchAction: 'none',
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
                onMouseDown={!isMobile ? handleMouseDown : undefined}
                onMouseMove={!isMobile ? handleMouseMove : undefined}
                onMouseUp={!isMobile ? handleMouseUp : undefined}
                onMouseLeave={!isMobile ? handleMouseUp : undefined}
            >{/* Background Pattern */}
                <div
                    className="absolute inset-0"
                    style={{
                        backgroundImage: `radial-gradient(circle at 30px 30px, rgba(255, 255, 255, 0.03) 0%, transparent 8px)`,
                        backgroundSize: '60px 60px',
                    }}
                />

                {/* Ambient Particles */}
                <div className="absolute inset-0 pointer-events-none">
                    {[...Array(isMobile ? 10 : 20)].map((_, i) => (
                        <div
                            key={i}
                            className="absolute w-1 h-1 bg-white rounded-full animate-float"
                            style={{
                                left: `${Math.random() * 100}%`,
                                top: `${Math.random() * 100}%`,
                                opacity: 0.2,
                                animationDelay: `${Math.random() * 5}s`,
                                animationDuration: `${5 + Math.random() * 5}s`
                            }}
                        />
                    ))}
                </div>

                {/* Foods */}
                {foods.map(food => !food.sliced ? (
                    <div
                        key={food.id}
                        className={`absolute transform transition-transform duration-100
                        ${isMobile ? 'text-3xl' : 'text-4xl'}`}
                        style={{
                            left: food.x,
                            top: food.y,
                            transform: `translate(-50%, -50%) rotate(${food.rotation || 0}deg)`,
                            filter: 'drop-shadow(0 0 10px rgba(255,255,255,0.2))',
                        }}
                    >
                        {food.type}
                    </div>
                ) : (
                    <React.Fragment key={food.id}>
                        {/* Left Half */}
                        <div
                            className={`absolute transform transition-transform duration-300
                            ${isMobile ? 'text-3xl' : 'text-4xl'}`}
                            style={{
                                left: food.leftHalfX,
                                top: food.leftHalfY,
                                transform: `translate(-50%, -50%) rotate(${-90}deg)`,
                                opacity: 0.8,
                                filter: 'drop-shadow(0 0 5px rgba(255,255,255,0.2))',
                            }}
                        >
                            {food.type}
                        </div>
                        {/* Right Half */}
                        <div
                            className={`absolute transform transition-transform duration-300
                            ${isMobile ? 'text-3xl' : 'text-4xl'}`}
                            style={{
                                left: food.rightHalfX,
                                top: food.rightHalfY,
                                transform: `translate(-50%, -50%) rotate(${90}deg)`,
                                opacity: 0.8,
                                filter: 'drop-shadow(0 0 5px rgba(255,255,255,0.2))',
                            }}
                        >
                            {food.type}
                        </div>
                    </React.Fragment>
                ))}

                {/* Splash Effects */}
                {splashes.map(splash => (
                    <div
                        key={splash.id}
                        className="absolute"
                        style={{
                            left: splash.x,
                            top: splash.y,
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <div className="relative">
                            {splash.particles.map((particle, i) => (
                                <div
                                    key={i}
                                    className="absolute animate-splash"
                                    style={{
                                        width: isMobile ? '4px' : '6px',
                                        height: isMobile ? '4px' : '6px',
                                        backgroundColor: splash.color,
                                        borderRadius: '50%',
                                        transform: `rotate(${particle.angle}rad) translateX(${((Date.now() - splash.timestamp) / 1000) * particle.speed * 50
                                            }px)`,
                                        opacity: Math.max(0, 1 - (Date.now() - splash.timestamp) / 500),
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                ))}

                {/* Blade Trail */}
                {(isDrawing || isTouching) && bladeTrail.length > 1 && (
                    <svg
                        className="absolute top-0 left-0 w-full h-full pointer-events-none"
                        style={{ zIndex: 1000 }}
                    >
                        <defs>
                            <linearGradient id="bladeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stopColor="rgba(255,255,255,0.1)" />
                                <stop offset="50%" stopColor="rgba(255,255,255,0.8)" />
                                <stop offset="100%" stopColor="rgba(255,255,255,0.1)" />
                            </linearGradient>
                            <filter id="bladeGlow">
                                <feGaussianBlur stdDeviation="2" result="coloredBlur" />
                                <feMerge>
                                    <feMergeNode in="coloredBlur" />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                        <path
                            d={`M ${bladeTrail.map(point => `${point.x},${point.y}`).join(' L ')}`}
                            fill="none"
                            stroke="url(#bladeGradient)"
                            strokeWidth={isMobile ? 8 : 4}
                            strokeLinecap="round"
                            filter="url(#bladeGlow)"
                        />
                    </svg>
                )}

                {/* Game Over Overlay */}
                {gameOver && (
                    <div
                        className="absolute inset-0 bg-black bg-opacity-70 flex flex-col items-center justify-center backdrop-blur-sm"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className={`${isMobile ? 'text-3xl' : 'text-5xl'} text-yellow-400 mb-4 animate-bounce`}>
                            Game Over!
                        </div>

                        {isNewHighScore && (
                            <div className="text-2xl text-green-400 mb-4 animate-pulse">
                                New High Score! 🎉
                            </div>
                        )}

                        <div className={`${isMobile ? 'text-xl' : 'text-3xl'} text-white mb-4`}>
                            Score: {score}
                        </div>

                        {submitError && (
                            <div className="text-red-400 mb-4">
                                {submitError}
                            </div>
                        )}

                        {showLeaderboard && leaderboard.length > 0 && (
                            <div className="bg-black bg-opacity-50 p-4 rounded-lg mb-4 max-h-48 overflow-y-auto">
                                <h3 className="text-xl text-yellow-400 mb-2">Leaderboard</h3>
                                {leaderboard.map((entry, index) => (
                                    <div key={entry._id} className="text-white flex justify-between gap-4">
                                        <span>#{index + 1} {entry.user_name}</span>
                                        <span>{entry.score}</span>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="flex gap-4">
                            <button
                                onClick={handleRestart}
                                onTouchEnd={(e) => {
                                    e.preventDefault();
                                    handleRestart(e);
                                }}
                                className={`px-6 py-3 bg-yellow-500 text-white rounded-lg 
                                ${isMobile ? 'text-lg' : 'text-xl'}
                                hover:bg-yellow-600 active:bg-yellow-700
                                transition-all transform hover:scale-105 active:scale-95
                                cursor-pointer touch-none select-none`}
                                style={{
                                    WebkitTapHighlightColor: 'transparent',
                                }}
                            >
                                Play Again
                            </button>

                            {submitError && submitError.includes("log in") && (
                                <button
                                    onClick={() => navigate('/login')} // Adjust the route as needed
                                    className={`px-6 py-3 bg-blue-500 text-white rounded-lg 
                                    ${isMobile ? 'text-lg' : 'text-xl'}
                                    hover:bg-blue-600 active:bg-blue-700
                                    transition-all transform hover:scale-105 active:scale-95
                                    cursor-pointer touch-none select-none`}
                                    style={{
                                        WebkitTapHighlightColor: 'transparent',
                                    }}
                                >
                                    Login to Save Score
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FoodGame;