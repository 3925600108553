import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/auth/login";
import { Provider } from "react-redux";
import store from "./redux/store";
import Dashboard from "./pages/cafe_admin/dashboard";
import Menu from "./pages/cafe_admin/menu";
import Signup from "./pages/auth/signup";
import AddItem from "./pages/cafe_admin/addItem";
import Analytics from "./pages/cafe_admin/analytics";
import RegisterCafe from "./pages/cafe_admin/registerCafe";
import Orders from "./pages/cafe_admin/orders";
import UserMenu from "./pages/user/menu";
import Bag from "./pages/user/bag";
import Init from "./pages/user/init";
import Register from "./pages/user/register";
import Waiting from "./pages/user/waiting";
import Page404 from "./pages/user/404";
import Bill from "./pages/user/bill";
import Rejected from "./pages/user/rejected";
import ClosedCafe from "./pages/user/closedCafe";
import Profile from "./pages/cafe_admin/profile";
import Cafeprofile from "./pages/user/profile"
import Review from "./pages/user/review";
import Invoice from "./pages/user/invoice";
import Alerts from "./pages/cafe_admin/alerts";
import CafeFromAlert from "./pages/user/cafeFromAlert";
import OrdersAll from "./pages/cafe_admin/orderAll";
import Offer from "./pages/user/offer"
import SuperadminLogin from "./pages/superadmin/SuperadminLogin";
import SuperadminDashboard from "./pages/superadmin/SuperadminDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import UserLogin from "./pages/user/userlogin"
import PermissionRoute from "./components/PermissionRoute";
import Frontpage from "./pages/user/frontpage";
import UserReview from "./pages/cafe_admin/userreview";
import Home from "./pages/user/Home"
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { recordAnalyticsEvent } from './api/analytics';
import CafeReviewPage from "./pages/user/CafeReviewPage";
import ReviewStatsDashboard from "./pages/cafe_admin/ReviewStatsDashboard";
import CafeProfile from "./pages/user/CafeProfile";
import Settings from "./pages/cafe_admin/settings";
import AllActiveOffers from "./pages/user/AllActiveOffers";
import OfferCreationPage from "./pages/cafe_admin/OfferCreationPage";
import AllOfferAnalytics from "./components/analytics/AllOfferAnalytics";
import CafeActiveOffers from "./pages/user/CafeActiveOffers";
import Thankyou from "./pages/user/thankyou";
import FoodcourtCafeList from "./components/foodcourt/FoodcourtCafeList";
import FoodcourtInit from "./components/foodcourt/FoodcourtInit";
import FoodGame from "./components/games/FruitNinja";

const TrackedRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (location.pathname.startsWith('/cafe/')) {
      recordAnalyticsEvent('navigation', location.pathname, params.cafe);
    }
  }, [location, params.cafe]);

  return <Component {...rest} />;
};

const router = createBrowserRouter([
  {
    path: "/cafe-admin/login",
    element: <Login />,
  },
  {
    path: "/cafe-admin/signup",
    element: <Signup />,
  },
  {
    path: "/cafe-admin/register",
    element: <RegisterCafe />,
  },
  {
    path: "/cafe-admin/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/cafe-admin/menu",
    element: <Menu />,
  },
  {
    path: "/cafe-admin/add",
    element: <AddItem />,
  },
  {
    path: "/cafe-admin/analytics",
    element: <Analytics />,
  },
  {
    path: "/cafe-admin/orders/all",
    element: (
      <PermissionRoute
        component={OrdersAll}
        permission="orders"
      />
    ),
  },
  {
    path: "/cafe-admin/orders/",
    element: (
      <PermissionRoute
        component={Orders}
        permission="orders"
      />
    ),
  },
  {
    path: "/cafe-admin/profile",
    element: <Profile />,
  },
  {
    path: "/cafe-admin/alerts",
    element: <Alerts />,
  },
  {
    path: "/cafe-admin/review-stats",
    element: <ReviewStatsDashboard/>,
  },
  {
    path: "/cafe-admin/settings",
    element: <Settings/>
  },
  {
    path: "/cafe-admin/edit-profile",
    element: <OfferCreationPage/>
  },
  {
    path: "/cafe-admin/all-offer-analytics",
    element: <AllOfferAnalytics/>
  },


  {
    path: "/cafe/:cafe",
    element: <TrackedRoute component={Init} />,
  },
  {
    path: "/cafe/:cafe/offers",
    element: <TrackedRoute component={CafeActiveOffers} />,
  },

  {
    path: "/cafe/menu",
    element: <TrackedRoute component={UserMenu} />,
  },
  {
    path: "/cafe/bag",
    element: <Bag />,
  },
  {
    path: "/cafe/register",
    element: <Register />,
  },
  {
    path: "/order/waiting",
    element: <Waiting />,
  },
  {
    path: "/order/bill/:id",
    element: <Bill />,
  },
  {
    path: "/order/invoice/:id",
    element: <Invoice />,
  },
  {
    path: "/order/rejected",
    element: <Rejected />,
  },
  {
    path: "cafe/closed",
    element: <ClosedCafe />,
  },
  {
    path: "review/:order",
    element: <Review />,
  },
  {
    path: "alert/cafe/:cafe",
    element: <CafeFromAlert />,
  },
  {
    path: "/superadmin/login",
    element: <SuperadminLogin />,
  },
  {
    path: "/superadmin/dashboard",
    element: <ProtectedRoute component={SuperadminDashboard} />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
  {
    path: "/cafe/Offers",
    element: <Offer />,
  },
  {
    path: "/user/login",
    element: <UserLogin />,
  },
  {
    path: "/front",
    element: <Frontpage />,
  },

  {
    path: "/userReview",
    element: <UserReview />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/cafe/review",
    element: <TrackedRoute component={CafeReviewPage}/>,
  },
{
  path: "/cafe/game",
  element: <FoodGame/>,
},
  {
    path: "/cafe/thank-you",
    element: <Thankyou/>,
  },
  {
    path: "/cafe/profile",
    element: <TrackedRoute component={CafeProfile} />,
  },
  {
    path: "/cafe/all-offers",
    element: <TrackedRoute component={AllActiveOffers} />,
  },

  {
    path: "/foodcourt/:foodcourtId",
    element: <TrackedRoute component={FoodcourtInit} />,
  },
  {
    path: "/foodcourt/:foodcourtId/cafes",
    element: <FoodcourtCafeList />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
    <Provider store={store}>
      <App>
        <RouterProvider router={router} />
      </App>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();