import axios from "axios";
const userUrl = process.env.REACT_APP_BASE_URL + "/api/user";

function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("vapid-key-db", 1);

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      db.createObjectStore("keys");
    };

    request.onsuccess = function (event) {
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

async function getVapidKeyFromDB() {
  try {
    const db = await openDatabase();
    const tx = db.transaction("keys", "readonly");
    const store = tx.objectStore("keys");
    const request = store.get("applicationServerKey");

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        resolve(event.target.result);
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error("Error fetching VAPID key from IndexedDB:", error);
    throw error;
  }
}

const createSession = async (cafe, table) => {
  try {
    const response = await axios.post(
      `${userUrl}/createSession/${cafe}?table=${table}`,
      { cafe, table }
    );
    localStorage.setItem("OG_session", response.data.data);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getCafe = async (cafe) => {
  try {
    const token = localStorage.getItem("OG_session");
    const response = await axios.get(`${userUrl}/cafe`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getCafeMenu = async (cafe) => {
  try {
    const token = localStorage.getItem("OG_session");
    const response = await axios.get(`${userUrl}/cafe/items`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const placeOrder = async (items, note, takeaway) => {
  try {
    let orderType = "";
    if (takeaway) {
      orderType = "TAKEAWAY";
    } else {
      orderType = "DINEIN";
    }
    const token = localStorage.getItem("OG_session");
    const response = await axios.post(
      `${userUrl}/order/place`,
      {
        items,
        orderType,
        note,
      },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const registerUser = async (data) => {
  try {
    const token = localStorage.getItem("OG_session");
    const vapidKey = await getVapidKeyFromDB();

    data.token = vapidKey;

    const response = await axios.post(`${userUrl}/order/register`, data, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const registerUserforoffer = async (data) => {
  try {
    const token = localStorage.getItem("OG_session");
    const vapidKey = await getVapidKeyFromDB();

    data.token = vapidKey;

    const response = await axios.post(`${userUrl}/order/register1`, data, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const fetchStatus = async () => {
  try {
    const token = localStorage.getItem("OG_session");
    const response = await axios.get(`${userUrl}/order/status`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const updateStatus = async (status) => {
  try {
    const token = localStorage.getItem("OG_session");
    const response = await axios.post(
      `${userUrl}/order/status`,
      {
        status,
      },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const fetchOrderToRate = async (order) => {
  try {
    const response = await axios.get(`${userUrl}/review/fetchOrder/${order}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data;
  }
};

const addItemRating = async (item, rating) => {
  try {
    const response = await axios.post(`${userUrl}/rating/item/${item}`, {
      rating,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const addItemReview = async (item, review) => {
  try {
    const response = await axios.post(`${userUrl}/review/item/${item}`, {
      review,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const addOrderRating = async (order, rating) => {
  try {
    const response = await axios.post(`${userUrl}/rating/order/${order}`, {
      rating,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const addOrderReview = async (order, review) => {
  try {
    const response = await axios.post(`${userUrl}/review/order/${order}`, {
      review,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const fetchOrder = async (id) => {
  const token = localStorage.getItem("OG_session");
  try {
    const response = await axios.get(`${userUrl}/order/fetch`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data;
  }
};

const getCafeFromAlert = async (cafe) => {
  try {
    const response = await axios.get(`${userUrl}/alert/${cafe}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const fetchReviewQuestions = async (cafeId) => {
  try {
    const encodedCafeId = encodeURIComponent(cafeId);
    const response = await axios.get(`${userUrl}/review/questions/${encodedCafeId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching review questions:', error);
    throw error;
  }
};

const submitCafeReview = async (cafeId, review, phone, sources) => {
  try {
    const encodedCafeId = encodeURIComponent(cafeId);
    const response = await axios.post(`${userUrl}/review/submit/${encodedCafeId}`, { reviews: [review], phone, sources });
    return response.data;
  } catch (error) {
    console.error('Error submitting cafe review:', error);
    throw error;
  }
};

const updateCafeReview = async (reviewId, reviewText) => {
  try {
    const response = await axios.put(`${userUrl}/review/update/${reviewId}`, { reviewText });
    return response.data;
  } catch (error) {
    console.error('Error updating cafe review:', error);
    throw error;
  }
};

// Add a new function to get user UUID
const getUserUUID = async (phone) => {
  try {
    const response = await axios.get(`${userUrl}/uuid/${phone}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user UUID:', error);
    throw error;
  }
};

const getActiveOffers = async (cafeId) => {
  try {
    const encodedCafeId = encodeURIComponent(cafeId);
    const response = await axios.get(`${userUrl}/cafe/${encodedCafeId}/offers`);
    return response.data;
  } catch (error) {
    console.error('Error fetching active offers:', error);
    throw error;
  }
};

const getGalleryImages = async (cafeId) => {
  try {
    const encodedCafeId = encodeURIComponent(cafeId);
    const response = await axios.get(`${userUrl}/cafe/${encodedCafeId}/gallery`);
    return response.data;
  } catch (error) {
    console.error('Error fetching gallery images:', error);
    throw error;
  }
};

const getCafeDetails = async (cafeId) => {
  try {
    const encodedCafeId = encodeURIComponent(cafeId);
    const response = await axios.get(`${userUrl}/cafe/${encodedCafeId}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cafe details:', error);
    throw error;
  }
};
const getAllActiveOffers = async () => {
  try {
    const response = await axios.get(`${userUrl}/cafe/all-offers`);
    return response.data;
  } catch (error) {
    console.error('Error fetching active offers for all cafes:', error);
    throw error;
  }
};
const getGoogleReviewLink = async () => {
  try {
    const cafeData = sessionStorage.getItem('OG_cafe');
    if (!cafeData) {
      throw new Error('Cafe data not found in session storage');
    }
    const cafeObj = JSON.parse(cafeData);
    const encodedCafeId = encodeURIComponent(cafeObj._id);
    const response = await axios.get(`${userUrl}/cafe/${encodedCafeId}/google-review-link`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Google review link:', error);
    throw error;
  }
};
const getCafeItemsByCategory = async (cafeId) => {
  try {
    const encodedCafeId = encodeURIComponent(cafeId);
    const response = await axios.get(`${userUrl}/cafe/${encodedCafeId}/items-by-category`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cafe items by category:', error);
    throw error;
  }
};

const submitGameScore = async (score) => {
  try {
      const phone = localStorage.getItem("OG_phone"); // Get phone from localStorage
      if (!phone) {
          throw new Error("User phone number not found");
      }

      const response = await axios.post(
          `${userUrl}/game/score`,
          { 
              score,
              phone 
          }
      );
      return response.data;
  } catch (error) {
      console.error('Error submitting game score:', error);
      throw error;
  }
};

const getLeaderboard = async (limit = 10, offset = 0) => {
  try {
      const response = await axios.get(
          `${userUrl}/game/leaderboard?limit=${limit}&offset=${offset}`
      );
      return response.data;
  } catch (error) {
      console.error('Error fetching leaderboard:', error);
      throw error;
  }
};

const getUserHighScore = async () => {
  try {
      const token = localStorage.getItem("OG_session");
      const response = await axios.get(
          `${userUrl}/game/highscore`,
          {
              headers: {
                  "x-auth-token": token,
              }
          }
      );
      return response.data;
  } catch (error) {
      console.error('Error fetching user high score:', error);
      throw error;
  }
};

const getGlobalBestScore = async () => {
  try {
      const response = await axios.get(`${userUrl}/game/global-best-score`);
      return response.data;
  } catch (error) {
      console.error('Error fetching global best score:', error);
      return { data: { score: 0, playerName: 'None' } };
  }
};

export {
  createSession,
  getCafe,
  getCafeMenu,
  placeOrder,
  registerUser,
  registerUserforoffer,
  fetchStatus,
  updateStatus,
  fetchOrderToRate,
  addItemRating,
  addItemReview,
  addOrderRating,
  addOrderReview,
  fetchOrder,
  getCafeFromAlert,
  fetchReviewQuestions,
  submitCafeReview,
  updateCafeReview,
  getUserUUID,
  getActiveOffers,
  getGalleryImages,
  getCafeDetails,
  getAllActiveOffers,
  getGoogleReviewLink,
  getCafeItemsByCategory,
  submitGameScore,
    getLeaderboard,
    getUserHighScore,
    getGlobalBestScore
};
