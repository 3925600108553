import React, { useCallback, useEffect, useState } from "react";
import { FaPlus } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { registerUser } from "../../api/user";
import Toaster from "../../components/Toaster";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import UniversalInput from "../../components/Universalnput"; // Fixed typo in import name
import NotificationPopup from "../../components/user/NotificationPermissionPopup";

const Register = () => {
  const [name, setName] = useState(() => localStorage.getItem("OG_name") || "");
  const [phone, setPhone] = useState(() => localStorage.getItem("OG_phone") || "");
  const [isUsername, setIsUsername] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(true);
  
  const location = useLocation();
  const navigate = useNavigate();
  const { isFoodcourt, foodcourtId } = location.state || {};

  const isIOS = useCallback(() => {
    const browserInfo = navigator.userAgent.toLowerCase();
    const platform = navigator?.userAgentData?.platform || navigator?.platform;

    return (
      ["iphone", "ipad"].some((device) => browserInfo.includes(device)) ||
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(platform)
    );
  }, []);

  useEffect(() => {
    const isUsernameRoute = sessionStorage.getItem("OG_isUsername") === "true";
    setIsUsername(isUsernameRoute);

    const name = localStorage.getItem("OG_name");
    const phone = localStorage.getItem("OG_phone");
    
    if (name && phone) {
      const registerAndRedirect = async () => {
        try {
          await registerUser({ name, phone });
          if (isFoodcourt) {
            navigate(`/foodcourt/${foodcourtId}/cafes`);
          } else {
            navigate(isUsernameRoute ? "/cafe/profile" : "/cafe/menu");
          }
        } catch (error) {
          navigate("/cafe/closed");
        }
      };
      
      registerAndRedirect();
    }
  }, [isFoodcourt, foodcourtId, navigate]);

  const requestNotificationPermission = useCallback(async () => {
    if (isIOS()) return;

    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      if (permission === "granted" && "serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.register("../service-worker.js", { scope: "/" });
          console.log("Service Worker Registered", registration);

          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY
          });

          await fetch(`${process.env.REACT_APP_BASE_URL}/api/user/subscribe`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ subscription, phone }),
          });

          console.log("Push Notification Subscription: ", subscription);
        } catch (err) {
          console.error("Service Worker registration failed: ", err);
        }
      }
    }
  }, [isIOS, phone]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (phone.length !== 10 || isNaN(phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    localStorage.setItem("OG_name", name);
    localStorage.setItem("OG_phone", phone);

    try {
      await registerUser({ name, phone });
      await requestNotificationPermission();
      if (isFoodcourt) {
        navigate(`/foodcourt/${foodcourtId}/cafes`);
      } else {
        navigate(isUsername ? "/cafe/profile" : "/cafe/menu");
      }
    } catch (error) {
      navigate("/cafe/closed");
    }
  }, [name, phone, isUsername, navigate, requestNotificationPermission, isFoodcourt, foodcourtId]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center px-8 bg-[#FAFAFA]">
      {showNotificationPopup && (
        <NotificationPopup 
          onClose={() => {
            setShowNotificationPopup(false);
            requestNotificationPermission();
          }}
        />
      )}
      <Toaster />
      <div className="text-center py-20">
        <h1 className="text-2xl font-extrabold mb-3">Welcome!</h1>
        <p className="text-xs">Sign in and embark on your culinary adventure with us. Your journey continues here!</p>
      </div>
      <form
        onSubmit={onSubmit}
        className="my-[100px] w-full flex flex-col items-center justify-center"
      >
        <div className="flex flex-col gap-[24px] w-full">
          <UniversalInput
            type="text"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
            className="h-[30px]"
            placeholder="Name"
            icon={FiUser}
            iconcss="text-xl text-black"
          />
          <UniversalInput
            type="number"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
            className="h-[30px] rounded-[30px]"
            placeholder="Phone Number"
            icon={FaPlus}
            iconcss="text-xs text-black"
            para="+91"
          />
          <button
            className="mt-4 text-xs flex justify-center items-center h-12 bg-[#9277FF] text-[#FFFFFF] rounded-lg font-bold px-8"
            style={{ boxShadow: "2px 3px 0px 0px #000000" }}
          >
            {isFoodcourt ? "View Cafes" : (isUsername ? "View Profile" : "View Menu")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default React.memo(Register);